import React from "react"
import { graphql, withPrefix } from "gatsby"
import Layout from "../components/Layout"
import Content from "../components/ContentMD"
import Menu from "../components/Menu"
import Gallery from "../components/Gallery"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout breadcrumbs={frontmatter.title}>
        <Gallery items={
            frontmatter.galImgs.map(image => ({
                original: withPrefix(image),
                thumbnail: withPrefix(image),
                })
            )}
        />

        <Content>
            {html}
        </Content>

        <div className="region region-content-bottom">
            <Menu level='1'
                items={['/home', '/about-us', '/what-we-do', '/our-work', '/contact-us']}
             />
            <Menu level='2'
                items={frontmatter.menu2}
             />
            <Menu level='3'
                items={frontmatter.menu3}
             />
            <Menu level='4'
                items={frontmatter.menu4}
             />
        </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        galImgs
        menu2
        menu3
        menu4
      }
    }
  }
`