import React from "react"
import { Link } from "gatsby"


export default function Menu({ level, items }) {
  let menuContent;
  if (items) {
    menuContent = (
      <div id={"block-menu-menu-level"+level} class="block block-menu last region-even odd region-count-2 count-3">
      <div className="content">
        <ul className="menu">
          {items.map((item) => (
            <li className='leaf'>
              <Link to={item === '/home' ? '/' : item } title="" activeClassName="active" partiallyActive={item === '/home'? false: true}>
                {item.replace(/.*\//mg, "").replace(/-/g, " ")}
              </Link>
            </li>
          ))}

        </ul>
      </div>
    </div>
    )
  }
  return (
    <>
      { menuContent }
    </>
  )
}